import React  from "react"

import Layout from "../components/layout"
import TimelineComponent from "../components/background"
import SEO from "../components/seo"
import { css } from "aphrodite"
import GridStyle from "../components/styles/skeleton/GridStyle"
import UtilityStyle from "../components/styles/skeleton/UtilityStyle"
import JColors from "../components/styles/JColors"
import { chooseBackground, isDayTime } from "../helpers"
import TypographyStyle from "../components/styles/skeleton/TypographyStyle"
import { Link } from "gatsby"

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="Home" />

      <div className={css(GridStyle.Container, UtilityStyle.u_maxHeight)}>
        <div>
          <div>
            <div className={css(JColors.Background_Photo, chooseBackground(), TypographyStyle.h5, JColors.Background_TextClip)}>
              <Link to="/about">About</Link>
            </div>
            <div style={{fontFamily: 'Not-Roman'}}>
              {isDayTime ?
                <p style={{color: 'white', verticalAlign: 'middle'}}>

                  now: <br/>
                  developer / @athenahealth <br/>
                  ―――<br/>
                  before: <br/>
                  @amtrak <br/>
                  several start ups  <br/>
                  ―――<br/>
                  education: <br/>
                  bs / the george washington university <br/><br/>
                </p>
                : <p style={{color: 'white', verticalAlign: 'middle'}}>

                  now: <br/>
                  developer / @athenahealth <br/>
                  ―――<br/>
                  before: <br/>
                  @amtrak <br/>
                  several start ups  <br/>
                  ―――<br/>
                  education: <br/>
                  bs / the george washington university <br/><br/>
                </p>
              }
            </div>
          </div>
        </div>
      </div>
      <TimelineComponent/>
    </Layout>
  )
}


export default AboutPage
